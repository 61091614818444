<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        收货地址编辑
      </template>
    </van-nav-bar>

    <div class="address">
      <div class="address-edit-box">

          <van-cell-group>
            <van-field
                v-model="info.username"
                maxlength="20"
                :error-message="name_error"
                required
                clearable
                label="姓名"
                placeholder="请输入姓名"
                @input="nameChange"
            />
            <van-field
                v-model="info.tel_number"
                required
                clearable
                label="手机号"
                placeholder="请输入手机号"
                :error-message="phone_error"
                @input="phoneChange"
            />
            <van-field
                required
                label="所在地"
                :error-message="address_error"
                @click="showAreaList"
                @click-input="showAreaList"
            >
              <template #input>
                <input class="address_title"  readonly  v-model="info.address"  @change="addressChange" placeholder="省/市/县（区）" @click="showAreaList"></input>
              </template>
            </van-field>
            <van-field
                v-model="info.address_detail"
                required
                clearable
                label="详细地址"
                placeholder="请输入详细地址"
                :error-message="address_detail_error"
                @input="addressDetailChange"
            />
            <van-field
                label="设置为默认"
                readonly
                @input="phoneChange"
            >
              <template #button>
                <van-switch :value="info.default === 1" @click="defaultChange"/>
              </template>
            </van-field>
          </van-cell-group>
        <div class="but-box">
          <van-button type="info" size="large" round block @click="onSave">保存</van-button>
          <van-button v-show="this.info.id > 0" type="danger" size="large" round block @click="onDelete">删除</van-button>
        </div>
        <van-popup v-model="showAreaPop"  position="bottom" :overlay="false" :safe-area-inset-bottom="true">
          <van-area  :area-list="areaList" @cancel="showAreaPop=false"  :columns-placeholder="['请选择', '请选择', '请选择']" @confirm="areaConfirm" />

        </van-popup>
      </div>

<!--      <van-address-edit :area-list="areaList" show-delete show-set-default show-search-result-->
<!--        :area-columns-placeholder="['请选择', '请选择', '请选择']" @save="onSave" @delete="onDelete" :address-info="{-->
<!--          name: info.username,-->
<!--          tel: info.tel_number,-->
<!--          addressDetail: info.address_detail,-->
<!--          province: info.province,-->
<!--          city: info.city,-->
<!--          areaCode: 350624,-->
<!--          postalCode: info.postalCode,-->
<!--          isDefault: info.default == 1,-->
<!--        }" />-->

    </div>
  </div>
</template>

<script>

import { areaList } from '@vant/area-data';
import { getAddressDetail,addressEdit,addressDel } from '@/request/api'


export default {
  name: 'AddressManagement',
  metaInfo: {
    title: '收货地址编辑', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {

      areaList,
      info: [],
      isAddress: true,
      uname: sessionStorage.getItem("uname"), //获取用户名
      chosenAddressId: "1",
      id: 0,
      name_error:'',
      phone_error:'',
      address_error:'',
      address_detail_error:'',
      showAreaPop:false,
    }
  },
  created() {
    this.id = this.$router.currentRoute.query.id;
    this.getAddress()
  },

  methods: {
    showAreaList(even){
      this.showAreaPop = true;
    },
    nameChange(e){
      if (e.length <= 1){
        this.name_error = '请输入姓名至少2个名字';
        return false;
      }else{
        this.name_error = '';
        return true;
      }
    },
    phoneChange(e){
      let tel_reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(e.length < 1){
        this.phone_error = '请输入手机号';
        return false;
      }else if (!tel_reg.test(e)){
        this.phone_error = '请输入正确的手机号';
        return true;
      }
    },
    addressChange(e){
      if(e.length <= 0){
        this.address_error = '请选择所在地';
        return false;
      }
      return true;
    },
    addressDetailChange(e){
      if(e.length < 5){
        this.address_detail_error = '请输入详细地址';
        return false;
      }
      return true;
    },
    areaConfirm(e){
      let length = e.length;
      let address = '';
      for (let i = 0; i < length; i++){
        if(e[i].code === ""){
          this.$toast.fail('请选择地址');
          return false;
        }
        address+= e[i].name;
      }
      this.info.address = address;
      this.showAreaPop = false;
      return true;
    },
    defaultChange(e){
      if(this.info.default == 0){
        this.info.default = 1;
      }else{
        this.info.default = 0;
      }
    },
    getAddress(){
      getAddressDetail({id:this.id})
          .then(res=>{
              if(res.data.code === 1){
                this.info  = res.data.data
                console.log(res.data.data)
              }
          })
          .catch(reason => {
            this.$toast.fail('网络连接失败')
          })
    },
    onBack() {
      history.back();
    },
    onSave() {
      // 验证输入数据
      if (!this.nameChange(this.info.username)
          & !this.phoneChange(this.info.tel_number)
          & !this.addressChange(this.info.address)
          & !this.addressDetailChange(this.info.address_detail)
      ) {
        return  false;
      }
      addressEdit({info:this.info})
          .then(res=>{
            console.log(res);
            let data = res.data;
            if(data.code === 1){
              this.$toast.success('保存成功')
              this.$router.back();
            }else{
              this.$toast.fail(data.msg)
            }
          })
          .catch(reason => {
            this.$toast.fail('网络连接失败')
            console.log(reason);
          })
    },
    onDelete() {
      //发送请求连接删除地址接口
      addressDel({id:this.info.id})
          .then(res=>{
            if(res.data.code === 1){
              this.$toast.success("删除成功");
              this.$router.back();
            }else{
              this.$toast.fail(res.data.msg);
            }
          })
          .catch(reason => {
            console.log('catch',reason)
            this.$toast.fail('网络连接失败');
          })
    },
  },
  components: {

  },
  computed: {

  },
}
</script>

<style lang = "less" scoped>
.address {
  padding: 7rem 1rem 1rem 1rem;
}

.van-submit-bar {
  bottom: 0rem;
  border-top: 0.1rem solid #e7e9ed;
}
.address_title{
   border: none;
  color: #646566;
}
.but-box{
  padding: 2rem 0;
}
.van-button--large{
  height: 5rem !important;
  margin-bottom: 2rem;
}
</style>