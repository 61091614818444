<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        收货地址管理
      </template>
    </van-nav-bar>

    <div class="address">
      <van-address-list
        v-model="address_id"
        :list="addressList"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="updateOrderAddressId"
      />

    </div>
    <van-overlay :show="show" z-index="1000">
      <van-loading size="32" type="spinner" ></van-loading>
    </van-overlay>
  </div>

</template>

<script>
 import { addressList,orderAddressUpdate } from '@/request/api'
 import {clear} from "core-js/internals/task";
 import {clearTime} from "element-ui";



 export default {
  name: 'AddressManagement',
  metaInfo: {
    title: '收货地址管理', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      show:false,
      isAddress: true,
      uname: sessionStorage.getItem("uname"), //获取用户名
      chosenAddressId: "1",

      addressList: [],
      order_id:0,
      address_id:0,
    }
  },
  created() {
    this.order_id = this.$router.currentRoute.query.order_id;
    this.address_id = parseInt(this.$router.currentRoute.query.address_id);
    console.log(this.address_id)
  },
  mounted() {
    this.getAddressList();
  },
  methods: {
    getAddressList(){
      addressList()
          .then(res=>{
            console.log(res)
            let data = res.data;
            if(data.code === 1){
              this.addressList = data.data.data;
            }else{
              this.$toast.fail(data.msg)
            }
          })
          .catch(reason => {
            this.$toast.fail('网络连接失败')
          })
    },
    onAdd() {
      this.$router.push("/addressEdit");
    },
    //点击编辑传地址id给地址编辑，以便地址回显
    onEdit(item, index) {
      let id = this.addressList[index].id;
      this.$router.push({name:'AddressEdit',query: {id}});
    },
    updateOrderAddressId(item,id){

      this.show=true;
      let _this = this;
      orderAddressUpdate({id:item.id,order_id:this.order_id})
          .then(res=>{
            let data = res.data;
            this.show = false;
            if(data.code === 1){
              this.$toast.success('操作成功')
              let time_id = setTimeout(function () {
                clearTimeout(time_id)
                _this.onBack();
              },1500)
            }else{
              this.$toast.fail(data.msg)
            }
          })
          .catch(reason => {
            this.show = false;
            this.$toast.fail('网络连接失败')
          })
    },
    onBack() {
      this.$router.back();
    },
  },
  components: {

  },
  computed: {

  },
}
</script>
 
<style lang = "less" scoped>
.address {
  padding: 7rem 1rem 1rem 1rem;
}

.van-submit-bar {
  bottom: 0rem;
  border-top: 0.1rem solid #e7e9ed;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


</style>